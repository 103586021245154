import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Verificacion = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook para redirigir al usuario
  const { datosUsuario } = location.state || {}; // Acceder a los datos del estado

  const [codigo, setCodigo] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [mensajeColor, setMensajeColor] = useState('#0c6834'); // Color del mensaje

  const manejarVerificacion = async (e) => {
    e.preventDefault();

    try {
      const respuesta = await fetch('https://backendjarciplas.onrender.com/api/verificar-codigo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ codigo, datosUsuario: datosUsuario }),
      });

      const data = await respuesta.json();

      if (respuesta.ok) {
        setMensaje('Código verificado exitosamente. ¡Bienvenido!');
        setMensajeColor('#0c6834'); // Verde
        setTimeout(() => navigate('/login'), 2000); // Redirigir después de 2 segundos
      } else {
        setMensaje(data.message || 'Código incorrecto. Intenta de nuevo.');
        setMensajeColor('#ff4d4d'); // Rojo
      }
    } catch (error) {
      console.error('Error al verificar el código:', error);
      setMensaje('Error en la verificación. Intenta de nuevo.');
      setMensajeColor('#ff4d4d'); // Rojo
    }
  };

  // Estilos en objetos
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      fontFamily: 'Arial, sans-serif',
    },
    form: {
      backgroundColor: '#ffffff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '300px',
      textAlign: 'center',
    },
    label: {
      fontSize: '16px',
      marginBottom: '10px',
      color: '#333',
      fontWeight: 'bold',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      fontSize: '14px',
    },
    button: {
      backgroundColor: '#4CAF50',
      color: 'white',
      padding: '10px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      width: '100%',
      fontSize: '16px',
    },
    buttonHover: {
      backgroundColor: '#45a049',
    },
    mensaje: {
      marginTop: '15px',
      fontSize: '14px',
    },
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={manejarVerificacion}>
        <label style={styles.label}>Código de Verificación:</label>
        <input
          style={styles.input}
          type="text"
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
          required
        />
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
          type="submit"
        >
          Verificar
        </button>
        {mensaje && <p style={{ ...styles.mensaje, color: mensajeColor }}>{mensaje}</p>}
      </form>
    </div>
  );
};

export default Verificacion;


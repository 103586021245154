import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate para redirigir a login

function RecuperarContraseña() {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [nuevaContrasena, setNuevaContrasena] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [fase, setFase] = useState(1); // 1: Solicitar, 2: Cambiar la contraseña
  const navigate = useNavigate();  // Hook de redirección

  // Función para manejar la solicitud de recuperación de contraseña
  const manejarSolicitudRecuperacion = async () => {
    try {
      const response = await axios.post('https://backendjarciplas.onrender.com/api/solicitar-recuperacion-contrasena', { email });
      setMensaje(response.data.message);
      setFase(2); // Cambiar a la fase de ingresar token y nueva contraseña
    } catch (error) {
      setMensaje(error.response ? error.response.data.message : 'Error desconocido');
    }
  };

  // Función para manejar el cambio de contraseña
  const manejarCambioContrasena = async () => {
    try {
      const response = await axios.post('https://backendjarciplas.onrender.com/api/cambiar-contrasena', { token, nuevaContrasena });
      setMensaje(response.data.message);

      // Si el cambio fue exitoso, redirige a la página de login
      if (response.data.message === 'Contraseña actualizada correctamente.') {
        setTimeout(() => {
          navigate('/login'); // Redirige a la página de login
        }, 2000); // Espera 2 segundos antes de redirigir
      }
    } catch (error) {
      setMensaje(error.response ? error.response.data.message : 'Error desconocido');
    }
  };

  // Estilos en línea
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontFamily: 'Arial, sans-serif',
    },
    form: {
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      width: '300px',
      textAlign: 'center',
    },
    input: {
      width: '100%',
      padding: '10px',
      margin: '10px 0',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxSizing: 'border-box',
      fontSize: '14px',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      fontSize: '16px',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
    message: {
      fontSize: '14px',
      marginTop: '10px',
    },
    success: {
      color: 'green',
    },
    error: {
      color: 'red',
    },
    title: {
      marginBottom: '20px',
      fontSize: '24px',
      color: '#333',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        <h2 style={styles.title}>Recuperación de Contraseña</h2>

        {fase === 1 && (
          <div>
            <input
              style={styles.input}
              type="email"
              placeholder="Ingresa tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button style={styles.button} onClick={manejarSolicitudRecuperacion}>Solicitar código de recuperación</button>
          </div>
        )}

        {fase === 2 && (
          <div>
            <input
              style={styles.input}
              type="text"
              placeholder="Ingresa el token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <input
              style={styles.input}
              type="password"
              placeholder="Ingresa la nueva contraseña"
              value={nuevaContrasena}
              onChange={(e) => setNuevaContrasena(e.target.value)}
            />
            <button style={styles.button} onClick={manejarCambioContrasena}>Cambiar contraseña</button>
          </div>
        )}

        {mensaje && (
          <p style={mensaje.includes('error') ? styles.error : styles.success}>
            {mensaje}
          </p>
        )}
      </div>
    </div>
  );
}

export default RecuperarContraseña;
